import React, { Component } from "react";
import { connect } from "react-redux";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

class App extends Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexFlow: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 100
        }}
      >
        <Typography variant="h2">Overnatning og mad</Typography>

        <Divider style={{ width: "100vw", marginTop: 20, marginBottom: 10 }} />
        <Typography variant="h3">Hoteller i Odense</Typography>
        <a href="https://occ.dk/hoteller/">
          <img src="https://occ.dk/wp-content/uploads/2023/05/OCC_logo_sort.svg" style={{height: 300, width: 300}}/>
        </a>
        <a href="https://occ.dk/hoteller/" > <Typography variant="h6">
         Book overnatning
          </Typography> </a>
        <Typography variant="h6">
          Vi anbefaler Odense Congress Center - god overnatning til fornuftige penge! 
           HUSK AT BRUGE KODEN     HIPHOP25      (DET GIVER 15% RABAT)
        </Typography>
        <Divider style={{ width: "50vw", marginTop: 20, marginBottom: 10 }} />
        <Typography variant="h3">Restaurant Fionie</Typography>
        <a href="https://restaurant-fionie.dk/a-la-carte-menu/" >
          <img src="https://restaurant-fionie.dk/wp-content/uploads/2021/06/Restaurant_Fionie_by_SS.png.webp" style={{ width: 300, height: "auto", backgroundColor:"black", padding: 50 }} />
        </a>
        <a href="https://restaurant-fionie.dk/a-la-carte-menu/" > <Typography variant="h6">
         Restaurant Fionie - lækker mad til alle!
          </Typography> </a>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // todos: getVisibleTodos(state.todos, state.visibilityFilter)
});

const mapDispatchToProps = dispatch => ({
  //  toggleTodo: id => dispatch(toggleTodo(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
